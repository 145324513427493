import React, {ChangeEvent, useEffect, useState} from 'react';
import {
    AlertBanner,
    CurrencyInputWithLabel,
    Dropdown,
    DropdownItem,
    Input,
    RedAsterisk,
    RequiredFieldsBanner,
    RequiredFieldsSubheader,
    UnderlinedHeader
} from "../../components";
import Ownership from "../Ownership";
import {LegalEntityFormData, OwnershipDetailsFormData} from "../models/Ownership";
import {useAppDispatch} from "../../store/hooks";
import {PersonalAssetDetailsType, PersonalAssetTypeOptionsType, UnsavedPersonalAsset, PersonalLiabilityType} from "../models/PersonalAsset";
import {formatCurrency} from "../../utils/format";
import {setActiveFormAsset} from "../clientAssetsSlice";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import DataEntrySummary from "../../components/DataEntry/DataEntrySummary";
import {isNaN} from "mathjs";
import {mapToOwnershipWriteModel} from "../Ownership/mappers";
import deepEquals from "fast-deep-equal";
import {isPersonalAssetFormDataMissing} from "./validation";
import {validateOwnershipDetails} from "../Ownership/validation";
import {calculateInEstateFormValue} from "../AssetSummary/common/AssetSummaryCalculator";
import {hasSomeInEstateOwnershipInForm} from "../Ownership/helpers";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import {HistoryBlockModal} from "../../components/HistoryBlockModal/HistoryBlockModal";
import {InlineAlert} from "../../components/AlertBanner/InlineAlert";
const typeOptions = Object.freeze(["Primary Home", "Secondary Home", "Specialty Vehicle", "Automobiles", "Discretionary", "Other"]);
const typeDropdownItems = typeOptions.map(typeOption => (<DropdownItem key={typeOption}
                                                                       itemText={typeOption}
                                                                       value={typeOption}/>));

export type PersonalAssetProps = {
    formatTitle: (description: string) => string,
    handleSave: (unsavedPersonalAsset: UnsavedPersonalAsset) => Promise<void>,
    handleCancel: (isFormChanged: boolean) => void,
    defaultPersonalAsset: PersonalAssetDetailsType,
    defaultOwnershipDetails: OwnershipDetailsFormData,
    memberGroup: MemberGroup,
    legalEntities: LegalEntityFormData[],
    updateLegalEntities: (legalEntities: LegalEntityFormData[]) => void
    personalLiability: PersonalLiabilityType
};

const PersonalAssetForm = (props: PersonalAssetProps) => {
    const dispatch = useAppDispatch();
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    const [personalAssetDetails, updatePersonalAssetDetails] = useState<PersonalAssetDetailsType>(props.defaultPersonalAsset);
    const [isRequiredFieldsBannerShown, setRequiredFieldsBannerShown] = useState(false);
    const [isDescriptionInlineErrorShown, setIsDescriptionInlineErrorShown] = useState(false);
    const [isSaveButtonDisabled, updateSaveButtonDisabled] = useState(isProfileWithProposalsOrArchived);
    const [ownershipDetailsFormData, updateOwnershipDetailsFormData] = useState<OwnershipDetailsFormData>(props.defaultOwnershipDetails);
    const [isOwnershipPercentageErrorBannerShown, setOwnershipPercentageErrorBannerShown] = useState(false);
    const [showNavigationModal, setShowNavigationModal] = useState(true);
    const [showLoanBalanceError, setShowLoanBalanceError] = useState(false);
    const [liability] = useState<PersonalLiabilityType>(props.personalLiability);



    useEffect(() => {
        setRequiredFieldsBannerShown(isRequiredFieldsBannerShown && isAnyRequiredFieldEmpty());
    }, [personalAssetDetails.description, ownershipDetailsFormData.legalEntityOwnerships]);


    useEffect(() => {
        setOwnershipPercentageErrorBannerShown(isOwnershipPercentageErrorBannerShown && isOwnershipPercentageNotEqual100());
    }, [ownershipDetailsFormData.legalEntityOwnerships, ownershipDetailsFormData.memberOwnerships]);

    useEffect(() => {
        const totalValue = isNaN(personalAssetDetails.presentValue) ? 0 : personalAssetDetails.presentValue;
        dispatch(setActiveFormAsset({
            assetType: 'personalAsset',
            id: personalAssetDetails.id,
            inEstateValue: calculateInEstateFormValue(totalValue, ownershipDetailsFormData.memberOwnerships),
            description: personalAssetDetails.description,
            hasInEstateOwnership: hasSomeInEstateOwnershipInForm(mapToOwnershipWriteModel(ownershipDetailsFormData).memberOwnerships),
        }));
        return clearActiveFormAsset;
    }, [personalAssetDetails.description, personalAssetDetails.presentValue, ownershipDetailsFormData]);

    const isAnyRequiredFieldEmpty = () => {
        return isPersonalAssetFormDataMissing(personalAssetDetails, ownershipDetailsFormData);
    }

    const isOwnershipPercentageNotEqual100 = () => {
        return !validateOwnershipDetails(ownershipDetailsFormData).isTotalOwnedPercentageEqualTo100;
    }

    const handleSaveButton = async () => {
        const isRequiredFieldEmpty = isAnyRequiredFieldEmpty();
        if (isRequiredFieldEmpty) {
            setRequiredFieldsBannerShown(true);
        }

        const isOwnershipPercentageInvalid = isOwnershipPercentageNotEqual100();
        if (isOwnershipPercentageInvalid) {
            setOwnershipPercentageErrorBannerShown(true);
        }

        if (isRequiredFieldEmpty || isOwnershipPercentageInvalid) {
            return false;
        }
        const {description, presentValue, type} = personalAssetDetails;

        updateSaveButtonDisabled(true);
        setShowNavigationModal(false);

        const ownershipDetails = mapToOwnershipWriteModel(ownershipDetailsFormData);
        await props.handleSave({
            description,
            presentValue,
            type,
            ...ownershipDetails,
        });

        return true
    }

    const isFormChanged = () => {
        const updated = {
            ...personalAssetDetails,
            ...ownershipDetailsFormData
        };
        const initial = {
            ...props.defaultPersonalAsset,
            ...props.defaultOwnershipDetails
        }
        return !deepEquals(initial, updated);
    }

    const onCancelClick = () => {
        setShowNavigationModal(false)
        props.handleCancel(isFormChanged());
    };

    const clearActiveFormAsset = () => {
        dispatch(setActiveFormAsset(null));
    }
    const handleClose = () =>{
        setShowLoanBalanceError(false)
    }

    return (
        <aside aria-label="Account Summary" className="account-summary-section">
            <HistoryBlockModal
                when={isFormChanged() && showNavigationModal}
                itemType={'page'}
                onSave={handleSaveButton}
            />
            <DataEntryHeader
                className='dataEntryHeader'
                title={props.formatTitle(personalAssetDetails.description)}
                onPrimaryButtonClick={handleSaveButton}
                disablePrimaryButton={isSaveButtonDisabled}
                onSecondaryButtonClick={onCancelClick}
                primaryButtonText='Save'
                secondaryButtonText='Cancel'
            />
            <RequiredFieldsBanner showAlert={isRequiredFieldsBannerShown} itemType="personal asset"/>
            <AlertBanner
                showAlert={showLoanBalanceError}
                className= 'liability-width'
                icon =  'error'
                showCloseBtn ={true}
                type = 'warning'
                fullWidth={false}
                onClose =  {handleClose}
                message = "The value of this asset is lower than its corresponding liabilities."
            />
            <div className='personal-asset__form layout-data-entry-form'>
                <article>
                    <section>
                        <UnderlinedHeader
                            className="asset-details-section-header"
                            primaryText="Asset Details"
                            secondaryContent={<RequiredFieldsSubheader/>}
                        />
                        <div className="layout-data-entry-form__field">
                            <label id="type">
                                <b>Type</b>
                            </label>
                            <Dropdown
                                className="typeField"
                                name="type"
                                id="typeInput"
                                aria-label="type"
                                aria-labelledby="type"
                                size="medium"
                                value={personalAssetDetails.type}
                                onChange={(data: any) => {
                                    updatePersonalAssetDetails({
                                        ...personalAssetDetails,
                                        type: data?.value as PersonalAssetTypeOptionsType,
                                        description: data?.value as PersonalAssetTypeOptionsType
                                    })

                                    setIsDescriptionInlineErrorShown(!data?.value)
                                }}
                                disabled={isProfileWithProposalsOrArchived}
                            >
                                {typeDropdownItems}
                            </Dropdown>
                        </div>
                        <div className="layout-data-entry-form__field">
                            <label className="descriptionLabel" id="descriptionFieldInput-label">
                                <b>Description<RedAsterisk/></b>
                            </label>
                            <Input
                                name="descriptionField"
                                aria-label="descriptionFieldInput"
                                aria-labelledby="descriptionFieldInput-label"
                                id="descriptionFieldInput"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    const {value: newDescription} = e.target;
                                    updatePersonalAssetDetails({
                                        ...personalAssetDetails,
                                        description: newDescription
                                    });

                                }}
                                onBlur={() => setIsDescriptionInlineErrorShown(!personalAssetDetails.description.trim())}
                                error={isDescriptionInlineErrorShown ? "Description is required." : undefined}
                                removeMarginTop
                                size="medium"
                                type="text"
                                maxLength={100}
                                value={personalAssetDetails.description}
                                readOnly={isProfileWithProposalsOrArchived}
                            />
                        </div>
                        <CurrencyInputWithLabel
                            label="Present Value"
                            onChangeValue={(_event, value) => {
                                const updatedPersonalAsset = {
                                    ...personalAssetDetails,
                                    presentValue: value as number,
                                    inEstateValue: calculateInEstateFormValue(personalAssetDetails.presentValue, ownershipDetailsFormData.memberOwnerships)

                                };
                                updatePersonalAssetDetails(updatedPersonalAsset);
                                const inEstatePercentageTotal = ownershipDetailsFormData.memberOwnerships.reduce((previous, current) => {
                                    return previous + parseFloat(current.percentage);
                                }, 0);
                                const currentLiabilityValue = liability.personalLiabilities.reduce((x,pLiability) => x + pLiability.loanBalance, 0)

                                if (currentLiabilityValue > ((updatedPersonalAsset.presentValue)*inEstatePercentageTotal)/100){
                                    setShowLoanBalanceError(true) ;
                                }
                                if (currentLiabilityValue <= ((updatedPersonalAsset.presentValue)*inEstatePercentageTotal)/100){
                                    setShowLoanBalanceError(false) ;
                                }
                            }}
                            maxLength={12}
                            value={personalAssetDetails.presentValue}
                            onBlur={() => {
                                const currentInputValue = personalAssetDetails.presentValue;

                                if (isNaN(currentInputValue)) {
                                    updatePersonalAssetDetails({
                                        ...personalAssetDetails,
                                        presentValue: 0
                                    });
                                }
                            }}
                            readOnly={isProfileWithProposalsOrArchived}
                        />
                        {showLoanBalanceError &&
                        <div style={{paddingLeft: "210px",
                            paddingBottom: "15px",
                            color: "#994900FF",
                            fontSize: "12px",
                            lineHeight: "14px"}}>
                            <InlineAlert
                                className="inline-alert"
                                message={"Value is lower than its corresponding liabilities"}
                            />
                        </div>}



                    </section>

                    <Ownership
                        formData={ownershipDetailsFormData}
                        onFormDataChange={updateOwnershipDetailsFormData}
                        totalAssetValue={personalAssetDetails.presentValue}
                        isOwnershipPercentageErrorBannerShown={isOwnershipPercentageErrorBannerShown}
                        memberGroup={props.memberGroup}
                        legalEntities={props.legalEntities}
                        updateLegalEntities={props.updateLegalEntities}
                        isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                    />
                </article>
                <aside>
                    <DataEntrySummary
                        items={[
                            {
                                label: 'Description',
                                value: personalAssetDetails.description
                            },
                            {
                                label: 'Present Value',
                                value: formatCurrency(!isNaN(personalAssetDetails.presentValue) ? personalAssetDetails.presentValue : 0)
                            },
                        ]}
                        title='Asset Summary'
                    />
                </aside>
            </div>
        </aside>
    )
};
export default PersonalAssetForm;
